import styled from 'styled-components';

const StyledCareers = styled.div`
.title-wrapper {
   h2 {
    margin: 8.5rem 0 1rem 0;
   }
   h3 {
       margin: 1rem 0;
   }
   p {
       margin: 0 auto;
       padding: 0 7px;
       max-width: 750px;
       text-align: center;
       font-size: 18px;
       color: #60616F;
       opacity: 1;
   }
}
.section-job-cards {
    padding: 1rem;
    max-width: 1146px;
    margin: .5rem auto;
}
.subtitle-wrapper {
    h4 {
        text-transform: uppercase;
    }
    @media only screen and (max-width: 1199px) {
        h4 {
            text-align: center;
        }
    }
}
.section-under-hero {
  display: flex;
  flex-direction: column;
  p {
    max-width: 90%;
    margin: 0 auto 1rem auto;
    text-align: center;
  }
}
  .apply-now-button-wrapper {
    display: flex;
    justify-content: center;
    a {
      margin: 3rem 0 5rem 0;
    }
  }

`;

export default StyledCareers;
